import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import '../css/home.css';
import EllipseAnimation from '../components/aiinside';

function HomePage() {
    return (
        <HelmetProvider>
            <Helmet title="Homepage | StarkAi">
                <meta name="description" content="Welcome to StarkAi. The Future of AI is Here" />
            </Helmet>
            <div className="container">
                <div className="content">
                    <div className="left-side">
                        <EllipseAnimation />
                    </div>
                    <div className="right-side">
                        <h1>Unleashing the Power of Advanced AI</h1>
                        <p>
                            Welcome to StarkAi, where cutting-edge technology meets unparalleled innovation. 
                            Our platform is designed to be the ultimate AI hub, offering an array of advanced 
                            features that redefine the boundaries of what's possible.
                        </p>
                        <p>
                            Whether you're generating stunning images, engaging in intelligent conversations, 
                            or conducting deep research, StarkAi is equipped with over 500 high-performance GPUs 
                            to deliver lightning-fast results, every time. We are committed to pushing the limits 
                            of AI, empowering you to achieve extraordinary outcomes with unmatched speed and precision.
                        </p>
                        <p>
                            Join us on this exciting journey into the future, where the possibilities are endless 
                            and the power of AI is at your fingertips. Explore our features and discover how StarkAi 
                            can transform the way you work, create, and innovate.
                        </p>
                        <button className="explore-button">Explore Our Features</button>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    );
}

export default HomePage;
