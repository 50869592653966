import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from "./components/nav";
import Footer from "./components/footer";
//import CardContainer from "./components/CardContainer";
import HomePage from "./pages/home"
import NotFound from "./pages/404";
import About from "./pages/about";
import Pricing from "./pages/pricing";
import Tos from "./pages/tos";
import Tools from "./pages/tools";
import Pop from "./pages/pop";
import StoreVisitorId from "./components/aicr";
import webSocketManager from "./components/socket";
import Main from "./layout/main";
import Los from "./pages/los";
import "./App.css";
import ImageGenerationPage from "./pages/imagegen"
import { Chat } from "./pages/chatbox";

const { PUBLIC_URL } = process.env;

const App = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <StoreVisitorId />
      <Navigation ws={webSocketManager} />
      <Suspense fallback={<Main />}>
        <Routes>
          <Route path="/" element={<HomePage ws={webSocketManager} />} />
          <Route path="/start" element={<Los ws={webSocketManager} />} />
          <Route path="/about" element={<About ws={webSocketManager} />} />
          <Route path="/tools" element={<Tools ws={webSocketManager} />} />
          <Route path="/pricing" element={<Pricing ws={webSocketManager} />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/image" element={<ImageGenerationPage ws = {webSocketManager} />} />
          <Route
            path="/terms-of-service"
            element={<Tos ws={webSocketManager} />}
          />
          <Route
            path="/privacy-policy"
            element={<Pop ws={webSocketManager} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <Footer ws={webSocketManager} />
    </BrowserRouter>
  );
};

export default App;
