import "../css/chat.css";

export const Chat = () => {
  return (
    <>
      <div class="chat-container">
        <div class="message-container">
          <div class="message sender-message">Hello there!</div>
          <div class="message receiver-message">
            Hi! How can I help you today?
          </div>
        </div>

        <div class="message1">
          <input type="text" placeholder="Type your message..." />

          <button>Send</button>
        </div>
      </div>
    </>
  );
};
